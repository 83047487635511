@import './../../../../assets/scss/base/base.scss';
:global(.MuiModal-root:not(.MuiDialog-root)) {
  display: flex;
  align-items: center;
}

.modal {
  position: relative;
  margin: auto;
  width: 85vw;
  max-width: 1200px;
  z-index: 4;
  color: #000000de;
  padding: 0;
  border-radius: 20px;
  background: #f5f5f5;
  // background-color: #fff;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
  &.modal--lookup {
    width: 80vw;
  }
  
  &Content {
    position: relative;
  }

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0px;
    right: 0px;
    background: $brand-primary;
    z-index: 1;
    color: #fff !important;
    font-size: 18px !important;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 8px;
  }

  &Tabs {
    max-width: calc(100% - 80px);
    margin-left: 18px;
  }

  &Actions {
    display: flex;
  }

  &Body {
    position: relative;
    height: 100%;
    padding: 10px 30px 20px 30px;
    max-height: 650px;
    // min-height: 420px;
    overflow: auto;
    margin-top: 70px;

    &-form {
      padding-top: 20px;
    }
  }

  &PriceBody {
    position: relative;
    padding: 10px 0px 80px 0px;
    max-height: calc(100% - 70px);
    overflow: auto;
    top: 70px;
    height: 100%;

    &-form {
      padding-top: 20px;
    }
  }

  &Title {
    display: inline-flex;
    align-items: center;
    overflow: hidden;
    color: $brand-primary !important;
    background-color: #fafafa !important;
    border: 0;
    height: 45px !important;
    min-width: 3em !important;
    border-radius: 7.048px 7.048px 0px 0px;
    font-size: 0.8rem;
    padding: 0 0.8em !important;
    background-color: transparent;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 18px;
    &Icon {
      margin-right: 5px;
    }
  }

  &Tabs {
    background-color: $brand-primary;

    :global(.MuiTab-root) {
      opacity: 1 !important;
      color: #fafafa;
      border: 0;
      margin: 0;
      height: 48px !important;
      min-width: 3em !important;
      line-height: 1.6rem;
      font-size: 0.8rem;
      padding: 0 0.8em !important;
      margin-right: 5px;
      background-color: transparent;
      transition: 0.3s background-color 0s;
      text-transform: uppercase;
      min-height: auto;
      font-weight: 500;
      letter-spacing: normal;
      border-radius: 7.048px 7.048px 0px 0px;
      &:hover {
        background-color: rgba(250, 250, 250, 0.1);
      }
    }

    :global(.MuiTabs-scroller) {
      display: flex;
      align-items: center;
    }

    :global(.MuiTab-root.Mui-selected) {
      background-color: #fff;
      color: $brand-primary;
    }

    :global(.MuiTabs-indicator) {
      display: none;
    }
  }

  &TabPanel:global(.MuiTabPanel-root) {
    padding: 0;
    height: 100%;
  }

  &--fix {
    .modal__body {
      height: calc(100vh - 40px);
    }
  }

  @media screen and (max-width: 2000px) {
    &FullHeight {
      // height: 83vh;
      &Body {
        position: relative;
        // padding: 50px 30px 80px 20px;
        // max-height: calc(100% - 70px);

        overflow: auto;
        top: 70px;
        // height: 100%;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    &Body {
      min-height: 300px;
      max-height: 450px;
    }
  }

  //   @media screen and (min-width: 1800px) {
  //     &Body {
  //       max-height: 550px;
  //     }
  //   }

  :global(.css-1ix1p7n-MuiGrid-root > .MuiGrid-item) {
    padding-top: 6px !important;
  }
  .modal_modalBody .MuiTableContainer {
    height: calc(100vh - 254px);
  }
}
